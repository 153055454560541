import Logo from "../assets/LogoSvg.svg";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";
import { fIndianCurrency } from "../utils/Validations";
import Label from "./Label/Label";
import { useEffect, useState } from "react";
import LoginIcon from "@mui/icons-material/Login";
import { dispatch } from "../redux/config";
import {
  arrangeProfileState,
  clearToken,
  loginUser,
} from "../redux/slices/user";
import { useDispatch } from "react-redux";
import { logoutAction } from "../globalAction/action";
import NotificationBell from "src/notification/NotificationBell";

const Header = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const path = location.pathname;
  const login = useSelector((state: any) => state.user.userLogin);
  const collapsed = props.collapsed;
  const headerButton = props.headerButton;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const walletStyle = {
    textTransform: "capitalize",
    borderColor: "primary",
    borderRadius: 8,
    borderWidth: "2px",
    borderStyle: "solid",
  };
  const UserProfilePhoto = useSelector((state: any) =>
    state.user ? state.user.profileImage : ""
  );
  const handleClearToken: any = () => {
    logoutAction();
    dispatch(loginUser(false));
    dispatch(arrangeProfileState(""));
    dispatch(clearToken(""));
    localStorage.clear();
  };
  const [profileCount, setProfileCount] = useState(0);
  useEffect(() => {
    const profCount = localStorage.getItem("profileStatus");
    setProfileCount(profCount ? parseInt(profCount) : 0);
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "white",
          boxShadow:
            "0px 6px 12px -4px rgba(145, 158, 171, 0.12), 0px 0px 0px 0px rgba(145, 158, 171, 0.20)",
        }}
      >
        <Toolbar sx={{ px: { xs: "0", sm: "20px" } }}>
          <div style={{ flexGrow: 1 }}>
            {collapsed === undefined ? <img src={Logo} alt="" /> : ""}
          </div>
          <div>
            {path === "/login" || path === "/forget" || path === "/verify" ? (
              <Link to={"/"}>
                <Button
                  size="small"
                  color="inherit"
                  sx={{ px: 4 }}
                  style={{
                    maxWidth: "320px",
                    textTransform: "none",
                    fontSize: 15,
                    borderRadius: "8px",
                    backgroundColor: "rgba(145, 158, 171, 0.24)",
                    fontFamily: "Public Sans",
                    fontWeight: "700",
                    color: "black",
                  }}
                  fullWidth
                >
                  Sign Up
                </Button>
              </Link>
            ) : (
              <>
                <Stack
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                  sx={{
                    justifyContent: { xs: "flex-start", md: "center" },
                  }}
                >
                  {props.showWallet ? (
                    <>
                      <Stack
                        pt={1}
                        spacing={1}
                        direction={{ xs: "column", md: "row" }}
                      >
                        <Label
                          variant="soft"
                          color={"primary"}
                          sx={walletStyle}
                        >
                          AP Balance:{" "}
                          {fIndianCurrency(
                            props.BankBalanceData &&
                              props.BankBalanceData.availableBalance
                          ) || "₹" + " " + 0}
                        </Label>
                        <Label
                          variant="soft"
                          color={"secondary"}
                          sx={walletStyle}
                        >
                          Locked:{" "}
                          {fIndianCurrency(
                            props.BankBalanceData &&
                              props.BankBalanceData.lockedBalance
                          ) || "₹" + " " + 0}
                        </Label>
                        <Label
                          variant="soft"
                          color={"warning"}
                          sx={walletStyle}
                        >
                          Receivables:{" "}
                          {fIndianCurrency(
                            props.BankBalanceData &&
                              props.BankBalanceData.assuredRecievables
                          ) || "₹" + " " + 0}
                        </Label>
                      </Stack>
                      <NotificationBell />
                    </>
                  ) : (
                    ""
                  )}

                  {path === "/" ? (
                    <Link to={"/login"}>
                      <Button
                        size="small"
                        color="inherit"
                        sx={{ px: 4 }}
                        style={{
                          maxWidth: "320px",
                          maxHeight: "40px",
                          textTransform: "none",
                          fontSize: 15,
                          borderRadius: "8px",
                          backgroundColor: "rgba(145, 158, 171, 0.24)",
                          fontFamily: "Public Sans",
                          fontWeight: "700",
                          color: "black",
                        }}
                        fullWidth
                      >
                        Login
                      </Button>
                    </Link>
                  ) : (
                    <>
                      {!headerButton ? (
                        <>
                          <Button
                            size="small"
                            color="inherit"
                            sx={{ px: 4 }}
                            onClick={handleClearToken}
                            style={{
                              maxWidth: "320px",
                              maxHeight: "40px",
                              textTransform: "none",
                              fontSize: 15,
                              borderRadius: "8px",
                              backgroundColor: "rgba(145, 158, 171, 0.24)",
                              fontFamily: "Public Sans",
                              fontWeight: "700",
                              color: "black",
                            }}
                            fullWidth
                          >
                            Logout
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Stack>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
