import React, { useState, useEffect } from "react";

interface NetworkInformation {
  downlink: number;
  effectiveType: "slow-2g" | "2g" | "3g" | "4g" | "5g";
  type: "wifi" | "cellular" | "ethernet" | "bluetooth" | "none";
}

interface Navigator {
  connection?: NetworkInformation;
}

const NetworkStatus: React.FC = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [connectionStatus, setConnectionStatus] = useState<string>("");
  const [showOfflineMessage, setShowOfflineMessage] = useState<boolean>(false);
  const [showSlowMessage, setShowSlowMessage] = useState<boolean>(false);
  const [showOnlineMessage, setShowOnlineMessage] = useState<boolean>(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setConnectionStatus("online");
      setShowOnlineMessage(true);
      setShowOfflineMessage(false);
      setShowSlowMessage(false);

      setTimeout(() => {
        setShowOnlineMessage(false);
      }, 1000);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setConnectionStatus("offline");
      setShowOfflineMessage(true);
      setShowOnlineMessage(false);
      setShowSlowMessage(false);

      setTimeout(() => {
        setShowOfflineMessage(false);
      }, 1000);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    if ("connection" in navigator) {
      const connection: any = navigator.connection as NetworkInformation;
      const handleConnectionChange = () => {
        if (connection) {
          console.log(connection);
          if (connection.downlink < 1 || connection.effectiveType === "3g") {
            setConnectionStatus("slow");
            setShowSlowMessage(true);
            setShowOnlineMessage(false);
            setShowOfflineMessage(false);

            setTimeout(() => {
              setShowSlowMessage(false);
            }, 1000);
          } else if (isOnline) {
            setConnectionStatus("online");
            setShowOnlineMessage(true);
            setShowSlowMessage(false);
            setShowOfflineMessage(false);

            setTimeout(() => {
              setShowOnlineMessage(false);
            }, 1000);
          }
        }
      };

      handleConnectionChange();
      connection.addEventListener("change", handleConnectionChange);

      return () => {
        connection.removeEventListener("change", handleConnectionChange);
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
      };
    } else {
      setConnectionStatus(isOnline ? "online" : "offline");
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [isOnline]);

  const getLineStyle = () => {
    if (showOfflineMessage) {
      return { backgroundColor: "#f44336" };
    }
    if (showSlowMessage) {
      return { backgroundColor: "#ff9800" };
    }
    if (showOnlineMessage) {
      return { backgroundColor: "#4caf50" };
    }
    return {};
  };

  return (
    <>
      {(showOfflineMessage || showSlowMessage || showOnlineMessage) && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
            ...getLineStyle(),
          }}
        ></div>
      )}

      {showOfflineMessage && (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            backgroundColor: "#f44336",
            color: "white",
            textAlign: "center",
            fontSize: "12px",
            zIndex: 9998,
          }}
        >
          You are offline
        </div>
      )}

      {showSlowMessage && (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            backgroundColor: "#ff9800",
            color: "white",
            textAlign: "center",
            fontSize: "12px",
            zIndex: 9998,
          }}
        >
          Your internet connection is slow.
        </div>
      )}

      {showOnlineMessage && (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            backgroundColor: "#4caf50",
            color: "black",
            textAlign: "center",
            fontSize: "12px",
            zIndex: 9998,
          }}
        >
          Back to online
        </div>
      )}
    </>
  );
};

export default NetworkStatus;
