import {
  GSTValidation,
  business_email_validations,
  mobile,
  password,
  userName,
} from "./RegexRule";
import moment from "moment";
import { ToWords } from "to-words";

//  global Email Validations Function -----------------------------------!
export function emailValidation(e: any) {
  if (business_email_validations.test(e)) {
    return true;
  } else {
    return false;
  }
}

//  Global Password Validations -----------------------------------------!
export function passwordValidation(e: any) {
  if (password.test(e)) {
    return true;
  } else {
    return false;
  }
}
// .withMessage('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character.'),

export function mobileValidation(e: any) {
  if (mobile.test(e)) {
    return true;
  } else {
    return false;
  }
}
// userName validations
export function userNameValidation(e: any) {
  if (userName.test(e)) {
    return true;
  } else {
    return false;
  }
}
// Gst Number Validation
export function GSTNumberValidation(e: any) {
  if (GSTValidation.test(e)) {
    return true;
  } else {
    return false;
  }
}

// lower caseString Conversion
export function characterLowercase(e: any) {
  if (e) {
    return e.toLowerCase();
  }
}
// upperCase String Conversion
export function characterUpperCase(e: any) {
  if (e) {
    return e.toUpperCase();
  }
}

//
export const findObjectLength = (obj: any) => {
  return Object.keys(obj).length;
};
export const numberFormat = (value: any) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    currencyDisplay: "symbol", // Use symbol (₹) instead of currency code (INR)
  })
    .format(value)
    .replace("₹", "₹"); // Adding space after ₹ symbol
};

export const getPaymentDays = (valueDays: any) => {
  // upcoming days calculated by paymentDays + 1
  let upcommingDate = parseInt(valueDays) + 1;
  // today date / order created date
  let today = new Date();
  // calculated date as per payment days adding in today days
  let calculatedDate = today.setDate(today.getDate() + upcommingDate);
  // final date converted into string to specific format
  let finalDate = moment(calculatedDate).format("YYYY/MM/DD");
  return finalDate;
};

// get payment Days from selected date
export const daysConversionFromDate = (selectedDate: any) => {
  const today = new Date();
  // Specify the selected date in the format YYYY-MM-DD
  const selectedFinalDate = new Date(selectedDate); // Replace '2024-01-15' with your selected date
  // Calculate the difference in milliseconds
  const timeDifference = selectedFinalDate.getTime() - today.getTime();
  // Convert milliseconds to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};

export const StringDateConversion = (ValueDate: any) => {
  // let finalDate = moment(ValueDate).format("YYYY/MM/DD");
  //
  let finalDate = moment(ValueDate).format("DD/MM/YYYY");
  return finalDate;
};
export const splitDateConversion = (ValueDate: any) => {
  if (ValueDate) {
    let parts = ValueDate.split("T");
    let finalDate = moment(parts[0]).format("DD/MM/YYYY");
    return finalDate;
  } else {
    return "N/A";
  }
};
export const removeSubstring = (value: String) => {
  let removeString;
  if (value) {
    removeString = value.substring(0, 20);
  } else {
    removeString = "N/A";
  }
  return removeString;
};
export const PaymentType = (value: any) => {
  if (value === "partial") {
    return "Partial Payment";
  } else if (value === "full") {
    return "Full Payment";
  } else {
    return "N/A";
  }
};

export const checkValueOfObject = (obj: object) => {
  return Object.keys(obj).length;
};

export const isFDAllowedValidation = (daysCount: number) => {
  if (daysCount > 7) {
    return true;
  } else {
    return false;
  }
};

//  calculation Interest as per provided Bank Interest for Annual Bases.
export const calculateBankBalanceInterest = (value: any, paymentDays: any) => {
  let si =
    (parseInt(value ? value : "0") *
      6 *
      (parseInt(paymentDays ? paymentDays : "0") / 365)) /
    100;
  return si;
};

// Calculate FD Days - as per days selection in receivables
export const calculationFDDays = (FDDays: any, receivableDate: any) => {
  // Recievable receiving date
  let getRecievablesDueDate: any = new Date(receivableDate);
  // payment creation date as from buyer request using above payment DAYS fn
  let paymentCreateDate: any = getPaymentDays(FDDays);

  // date conversion of new Date
  paymentCreateDate = new Date(paymentCreateDate);

  // get days count using conversion btwn payment creation and receivable receiving date.
  var daysDifference: any = Math.round(
    (paymentCreateDate - getRecievablesDueDate) / (1000 * 60 * 60 * 24)
  );
  // return Date
  return daysDifference;
};

export const updateKeyById = (Array: any, Item: any) => {
  // Find the index of the object with the given orderId
  const index = Array.findIndex((item: any) => item.mid === Item.mid);
  // If the object is found, update the specified key
  if (index !== -1 && Array[index].hasOwnProperty("selectedColor")) {
    Array[index]["selectedColor"] =
      Array[index]["recievableToBeUsed"] > 0
        ? !Array[index]["selectedColor"]
        : false;
  }
  // Return the updated array
  return Array;
};

// Buyer Total Interest Details
export const totalReceivableAmount = (arr: any) => {
  const TotalUsedAmount = arr.reduce((acc: any, obj: any) => {
    return acc + parseFloat(obj.approxInterest);
  }, 0);
  return TotalUsedAmount;
};
// partial patment Make Payment Request
export const TotalInterestForPatialPayment = (arr: any) => {
  const TotalInterest = arr.reduce((acc: any, obj: any) => {
    return acc + parseFloat(obj.interest);
  }, 0);
  return TotalInterest;
};
export const TotalFeesForPatialPayment = (arr: any) => {
  const TotalInterest = arr.reduce((acc: any, obj: any) => {
    return acc + parseFloat(obj.ApFees);
  }, 0);
  return TotalInterest;
};

// partial payment Order Amount percentage
export const orderAmtInt = (orderAmt: number, percentValue: number) => {
  let int = orderAmt * percentValue;
  let interest = int / 100;
  return interest;
};

// Sum of Recievables
export const ReceivableAmt = (arr: any) => {
  const TotalSum = arr.reduce((acc: any, obj: any) => {
    return acc + parseFloat(obj.amount);
  }, 0);
  return TotalSum;
};

//  Full Payment Option
export const makeFullPayment = (arr: any) => {
  const TotalSum = arr.reduce((acc: any, obj: any) => {
    return acc + parseFloat(obj.interest);
  }, 0);
  return TotalSum.toFixed(2);
};

export const SumOfArrayWithKey = (arr: any) => {
  const TotalSum = arr.reduce((acc: any, obj: any) => {
    return acc + parseFloat(obj.amount);
  }, 0);
  return TotalSum.toFixed(2);
};
//  trim value for Numbers only
export const onlyNumberValidation = (value: any) => {
  return value.replace(/[^0-9]/g, "");
};
export const compareNumberValueValidation = (
  value: any,
  amount: any,
  milestone1?: number
) => {
  const newValue = value.replace(/[^0-9]/g, "");
  if (parseInt(newValue) < amount && !milestone1) {
    return;
  } else {
    if (milestone1) {
      let leftAmount: number = amount - milestone1;
      if (leftAmount) {
        return leftAmount;
      } else {
        return 0;
      }
    } else {
      return amount - 1;
    }
  }

  // return value.replace(/[^0-9]/g, '')
};

export const minMaxLimit = (itemValue: any) => {
  // let value = itemValue.replace(/[^0-9]/g, '');
  // Ensure the value is within the specified range
  let value = Math.min(Math.max(parseInt(itemValue), 0), 180).toString();
  return value;
};

// eligible receivable sum amount
export const totalEligibleReceivableAmt = (arr: any) => {
  const TotalSum = arr.reduce((acc: any, obj: any) => {
    return acc + parseFloat(obj.usuableRecievables);
  }, 0);
  return TotalSum.toFixed(2);
};
// eligible receivable sum amount
export const usedReceivableAmtFull = (arr: any) => {
  if (arr.length > 0) {
    const TotalSum = arr.reduce((acc: any, obj: any) => {
      return parseFloat(acc) + parseFloat(obj.receivablesToBeUsed);
    }, 0);
    return TotalSum;
  } else {
    return 0;
  }
};

export const findGreatest = (
  value1: number,
  value2: number,
  value3?: number
) => {
  const max = Math.max(value1, value2, value3 ? value3 : 0);

  if (
    value1 === value2 &&
    ((value3 ? value3 : 0) > 0 ? value3 === value2 : true)
  ) {
    return value1;
  } else {
    return max;
  }
};

export const numberToEnglish = (amount: number) => {
  function convertToWords(num: any): any {
    const toWords = new ToWords();
    if (amount) {
      return toWords.convert(amount);
    } else {
      return "Number out of range";
    }
  }

  if (amount > 0) {
    const words = convertToWords(amount);
    const formattedText = `${words.trim()} rupees only.`;
    return formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
  } else {
    return "";
  }
};

export const ReceivableAmtSum = (arr: any) => {
  if (arr.length <= 0) {
    return 0;
  } else {
    const TotalSum = arr.reduce((acc: any, obj: any) => {
      return acc + parseFloat(obj.amount);
    }, 0);
    return TotalSum;
  }
};

export function firstCharacterUppercase(e: any) {
  if (e) {
    return e[0].toUpperCase() + e.slice(1);
  }
}

export const amountInterest = (value: number, days: number) => {
  let si = ((value ? value : 0) * 6 * ((days ? days : 0) / 365)) / 100;
  return si;
};

export const formatString = (inputString: string) => {
  // Splitting the string based on underscore
  const splitString = inputString.split("_");

  // Capitalizing the first character of each word and joining them with space
  const result = splitString
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return result;
};

export const accurateDateFormat = (value: Date) => {
  const originalDate = new Date(value);

  // Extract the date components
  const year = originalDate.getUTCFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");

  // const day = (originalDate.getUTCDate() + 1).toString().padStart(2, "0");
  const day = moment(originalDate)
    .add(1, "days")
    .calendar()
    .toString()
    .padStart(2, "0");

  // Create the new formatted date string
  const formattedDateString = `${year}-${month}-${day}T00:00:00.000Z`;
  return formattedDateString;
};

export const fIndianCurrency = (number: number) => {
  const format = number ? number : "";
  return format.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    style: "currency",
    currency: "INR",
  });
};

export const isRemarksValid = (remarks: string) => {
  // const wordCount = remarks.trim().split(/\s+/).length;
  const characterCount = remarks.trim().length;

  return characterCount >= 99 && characterCount <= 199;
};

export function getCurrentTime() {
  const now = new Date();
  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  return `${hours}:${minutes} ${period}`;
}
export const formatedData = (value: string) => {
  const dateObject = new Date(value);
  const options: any = {
    // weekday: "long",
    year: "numeric",
    month: "short", // long
    day: "numeric",
  };
  const dateString = dateObject.toLocaleDateString("en-US", options);
  const timeString = dateObject.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  return `${dateString} (${dateObject.toLocaleDateString("en-US", {
    weekday: "short",
  })}), ${timeString}`;
  // };
  //   // const formattedDate = new Date(value).toLocaleString("en-US", {

  //   weekday: "long",
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  //   hour: "numeric",
  //   minute: "numeric",
  //   second: "numeric",
  //   hour12: true,
  // });
  // return formattedDate;
};
