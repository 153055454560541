// // ----------------------------------------------------------------------
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  error: null,
  userLogin: <any>[],
  login: false,
  Token: <any>{},
  LocationInfo: <any>{},
  userSave: <any>{},
  profileImage: <any>{},
  userInfo: <any>{},
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.isLoading = true;
      state.userLogin = action.payload;
    },
    saveUser: (state, action) => {
      state.isLoading = false;
      state.userSave = action.payload;
    },
    storeToken: (state, action) => {
      state.isLoading = true;
      state.Token = action.payload;
    },
    clearToken: (state, action) => {
      state.isLoading = true;
      state.Token = "";
    },
    logOutUser: (state, action) => {
      state.isLoading = true;
      state.userLogin = action.payload;
    },
    LocationInfo: (state, action) => {
      state.isLoading = true;
      state.LocationInfo = action.payload;
    },
    arrangeProfileState: (state, action) => {
      state.isLoading = true;
      state.profileImage = action.payload;
    },
    userInfo: (state, action) => {
      state.isLoading = true;
      state.profileImage = action.payload;
    },
  },
});
export const {
  loginUser,
  logOutUser,
  storeToken,
  LocationInfo,
  saveUser,
  arrangeProfileState,
  clearToken,
  userInfo,
} = userSlice.actions;

export default userSlice.reducer;
