import React, { useState, useEffect } from "react";
import {
  Badge,
  IconButton,
  MenuItem,
  Button,
  Stack,
  Drawer,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import initializeNotification from "./notificationService";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Typography12 } from "src/components/typography/Typography";

const NotificationBell = () => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });

  useEffect(() => {
    initializeNotification((notification) => {
      setNotifications((prev) => [notification, ...prev]);
      setUnreadCount((prev) => prev + 1);
      setSnackbar({
        open: true,
        message: `${notification.title}: ${notification.body}`,
      });
    });
  }, []);

  const handleNotificationClick = (index: number) => {
    setNotifications((prev) => prev.filter((_, i) => i !== index));
    setUnreadCount((prev) => Math.max(prev - 1, 0));
  };

  console.log(snackbar);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleNotificationDelete = (index: number) => {
    setNotifications((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      <div>
        <IconButton
          color="inherit"
          onClick={toggleDrawer}
          sx={{
            position: "relative",
            border: "1px solid",
            borderColor: 0.5,
            borderRadius: "50%",
            backgroundColor: "grey.300",
            padding: "4px",
          }}
        >
          <Badge
            badgeContent={unreadCount}
            color="error"
            sx={{
              fontSize: "10px",
              padding: "2px",
              borderRadius: "50%",
              border: "1px solid",
              backgroundColor: "#e57373",
            }}
          >
            <NotificationsIcon sx={{ fontSize: "25px" }} />
          </Badge>
        </IconButton>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer}
          sx={{
            width: 350,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 350,
              height: "100%",
              backgroundColor: "#f4f6f8",
              padding: "16px",
            },
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="All" />
            <Tab label="Unread" />
            <Tab label="Read" />
          </Tabs>

          <Box sx={{ marginTop: 2 }}>
            {notifications.length > 0 ? (
              notifications
                .filter((notif) => {
                  if (tabValue === 0) return true;
                  return tabValue === 1
                    ? notif.status === "unread"
                    : notif.status === "read";
                })
                .map((notif, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: "#fff",
                      padding: "12px",
                      marginBottom: "12px",
                      borderRadius: "16px",
                      boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                      position: "relative",
                    }}
                  >
                    <Stack>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "normal",
                          lineHeight: "18px",
                          maxWidth: "275px",
                        }}
                      >
                        {notif.title || "Default Title"}
                      </Typography>

                      <Typography variant="subtitle2" color="text.secondary">
                        {notif.subject || "Default Subject"}
                      </Typography>
                    </Stack>
                    <Typography12 text={notif.body}></Typography12>

                    <Stack
                      direction="row"
                      alignItems={"flex-end"}
                      justifyContent={"flex-end"}
                      spacing={2}
                      sx={{ marginTop: "12px" }}
                    >
                      <Button
                        size="small"
                        onClick={() => handleNotificationClick(index)}
                        color="inherit"
                        sx={{
                          my: 1,
                          borderRadius: 2,
                          textTransform: "none",
                        }}
                        style={{
                          fontSize: 10,
                          backgroundColor: "#303981",
                          fontFamily: "Public Sans",
                          fontWeight: "700",
                          color: "white",
                        }}
                        variant="contained"
                      >
                        Mark As Read{" "}
                      </Button>
                      <Button
                        size="small"
                        color="inherit"
                        sx={{
                          my: 1,
                          borderRadius: 2,
                          textTransform: "none",
                        }}
                        style={{
                          fontSize: 10,
                          backgroundColor: "#303981",
                          fontFamily: "Public Sans",
                          fontWeight: "700",
                          color: "white",
                        }}
                        variant="contained"
                      >
                        Action{" "}
                      </Button>
                    </Stack>
                  </Box>
                ))
            ) : (
              <MenuItem>No new notifications</MenuItem>
            )}
          </Box>
        </Drawer>
      </div>
    </>
  );
};

export default NotificationBell;
