// import { routerType } from "../types/router.types";
// import OnBoardingPayment from "../pages/onBoardingPays/OnBoardingPayment";
// import ResetPassword from "../pages/login/ResetPassword";
// import CreateUserGstProfile from "../pages/GST/CreateUserGstProfile";
// import CreateBusinessProfile from "../pages/GST/CreateBusinessProfile";
// import AadharVerify from "../pages/Aadhar/AdharVerify";
// import PanVerify from "../pages/Pan/PanVerify";
// import PanUploadFiles from "../pages/Pan/PanUploadFiles";
// import AdminApproved from "../pages/Pan/AdminApproval";
// import Busi1 from "../pages/business/Busi1";
// import Busi2 from "../pages/business/Busi2";
// import Busi3 from "../pages/business/Busi3";
// import ReUpload from "../pages/Reupload/ReuploadDocs";
// import Dashboard from "../pages/Payments/Dashboard/Dashboard";
// import SelectPayment from "../pages/Payments/SelectPayment";
// import CreateNewPaymentBuyer from "../pages/Payments/CreatePaymentBuyer/CreateNewPaymentBuyer";
// import AddMaker from "../pages/Maker/AddMaker";
// import ViewMaker from "../pages/Maker/ViewMaker";
// import PaymentApproved from "../pages/Payments/ViewOrders/Completed/CompletOrder";
// import AcceptPaymentMain from "../pages/Payments/AcceptPayment/Accept/AcceptPaymentMain";
// import ApproveRejectPayment from "../pages/Payments/AcceptPayment/ApproveRejectPayment";
// import MakerRequestMain from "../pages/Payments/MakerRequests/MakerRequestMain";
// import BookPayablesMain from "../pages/Payments/BookedPayables/BookPayablesMain";
// import AvailableRecievablesMain from "../pages/Payments/AvailableRecievables/AvailableRecievablesMain";
// import CreateNewPaymentSeller from "../pages/Payments/CreatePaymentSeller/CreateNewPaymentSeller";
// import AvailableOrderDetails from "../pages/Payments/AvailableRecievables/AvailableOrderDetails";
// import BookPayablesOrder from "../pages/Payments/BookedPayables/BookPayablesOrder";
// import ExpiredPaymentRequest from "../pages/Payments/ViewOrders/Expired/ExpiredPaymentRequest";
// import RejectedPaymentRequest from "../pages/Payments/ViewOrders/Rejected/RejectedPaymentRequest";
// import CancelOrder from "../pages/Payments/ViewOrders/Canceled/CancelOrder";
// import ReuploadRequest from "../pages/Reupload/ReuploadRequest";
// import RateYourSeller from "../pages/BusinessRating/RateYourSeller";
// import GeneratePaymentRequestSeller from "../pages/Payments/ViewOrders/InProcess/Seller/GeneratePaymentRequestSeller";
// import AcceptRequestMain from "../pages/Payments/AcceptRequest/Accept/AcceptRequestMain";
// import AcceptRequestMainPage from "../pages/Payments/AcceptRequest/Accept/AcceptRequestMainPage";
// import ApproveRejectPaymentRequest from "../pages/Payments/AcceptRequest/ApproveRejectPaymentRequest";
// import ApprovePaymentRequestList from "../pages/Payments/AcceptRequest/ApprovePaymentRequestList";
// import PaymentRequestConfig from "../pages/Payments/CreatePaymentSeller/PaymentRequestConfig";
// import BuyerOrder from "../pages/Payments/Orders/Main";
// import SellerOrder from "../pages/Payments/RequestOrder/Main";
// import Chat from "../pages/Chat/Chat/Main";
// import Orders from "../pages/Payments/Dashboard/Orders";
// import Reports from "../pages/Reports/Main";
// import BuyerOrderView from "../pages/Payments/Buyer/Orders/Orders";
// import SellerOrderView from "../pages/Payments/Seller/Orders/Orders";
// import SellerPaymentRequestView from "../pages/Payments/Seller/PaymentRequest/PaymentRequest";
// import BuyerPaymentRequestView from "../pages/Payments/Buyer/PaymentRequest/PaymentRequest";
// import PRChat from "src/pages/Chat/Chat/PRChat";
// import OrderChat from "src/pages/Chat/Chat/OrderChat";

// const auth = "auth";
// const dashboard = "dashboard";
// export const authrouterData: routerType[] = [
//   {
//     path: `${auth}/reset`,
//     element: <ResetPassword />,
//     title: "Reset_Password",
//   },
//   {
//     path: `${auth}/onboardingpays`,
//     element: <OnBoardingPayment />,
//     title: "Boarding_Fees",
//   },
//   {
//     path: `${auth}/userkyc`,
//     element: <CreateUserGstProfile />,
//     title: "Create_User_Gst_Profile",
//   },
//   {
//     path: `${auth}/upload-files`,
//     element: <CreateBusinessProfile />,
//     title: "Create_User_Business_Profile",
//   },
//   {
//     path: `${auth}/verifyaadhar`,
//     element: <AadharVerify />,
//     title: "Aadhar_Verify",
//   },
//   {
//     path: `${auth}/verifypan`,
//     element: <PanVerify />,
//     title: "Pan_Verify",
//   },
//   {
//     path: `${auth}/panuploadfiles`,
//     element: <PanUploadFiles />,
//     title: "Pan_Upload_files",
//   },
//   {
//     path: `${auth}/approval`,
//     element: <AdminApproved />,
//     title: "Approval",
//   },
//   {
//     path: `${auth}/postrequest`,
//     element: <ReuploadRequest />,
//     title: "Reupload request",
//   },
//   {
//     path: `${auth}/reupload`,
//     element: <ReUpload />,
//     title: "Reupload",
//   },
// ];
// // export default routerData;
// export const dashboardRoutesData: routerType[] = [
//   {
//     path: `${dashboard}/main`,
//     element: <Dashboard />,
//     title: "Dashboard",
//   },
//   {
//     path: `${dashboard}/orders`,
//     element: <Orders />,
//     title: "My Orders",
//   },
//   {
//     path: `${dashboard}/addbusinessnetwork`,
//     element: <Busi1 />,
//     title: "Approval",
//   },
//   {
//     path: `${dashboard}/businesslist`,
//     element: <Busi2 />,
//     title: "addBusinessNetwork",
//   },
//   {
//     path: `${dashboard}/busi3`,
//     element: <Busi3 />,
//     title: "Busi3",
//   },
//   {
//     path: `${dashboard}/payment`,
//     element: <Dashboard />,
//     title: "Payment Dashboard",
//   },
//   {
//     path: `${dashboard}/selectpayment`,
//     element: <SelectPayment />,
//     title: "select Payment",
//   },
//   {
//     path: `${dashboard}/createpayment`,
//     element: <CreateNewPaymentBuyer />,
//     title: "Create Payment",
//   },
//   {
//     path: `${dashboard}/createpaymentrequest`,
//     element: <CreateNewPaymentSeller />,
//     title: "Create Seller Payment",
//   },
//   {
//     path: `${dashboard}/createmaker`,
//     element: <AddMaker />,
//     title: "Add Maker",
//   },
//   {
//     path: `${dashboard}/users`,
//     element: <ViewMaker />,
//     title: "View Maker",
//   },
//   //  Order Status Confirmation
//   {
//     path: `${dashboard}/order`,
//     element: <BuyerOrder />,
//     title: "Buyer Orders",
//   },

//   {
//     path: `${dashboard}/request`,
//     element: <SellerOrder />,
//     title: "Buyer Orders",
//   },
//   {
//     path: `${dashboard}/requestedorder`,
//     element: <BuyerOrder />,
//     title: "Buyer Orders",
//   },
//   //
//   // {
//   //   path: `${dashboard}/generaterequest`,
//   //   element: <GeneratePaymentRequest />,
//   //   title: "Generate Payment Request",
//   // },
//   {
//     path: `${dashboard}/orderrequest`,
//     element: <GeneratePaymentRequestSeller />,
//     title: "Generate Payment Request Seller",
//   },
//   {
//     path: `${dashboard}/completepayment`,
//     element: <PaymentApproved />,
//     title: "Complete Payment",
//   },
//   {
//     path: `${dashboard}/acceptpayments`,
//     element: <AcceptPaymentMain />,
//     title: "Approve Payment",
//   },
//   // {
//   //   path: `${dashboard}/acceptrequests`,
//   //   element: <AcceptRequestMain />,
//   //   title: "Accept  Request",
//   // },
//   {
//     path: `${dashboard}/approvepayments`,
//     element: <ApproveRejectPayment />,
//     title: "Accept Payment",
//   },
//   {
//     path: `${dashboard}/makerrequest`,
//     element: <MakerRequestMain />,
//     title: "Maker Request",
//   },
//   // Booked Payables order List
//   {
//     path: `${dashboard}/bookpayables`,
//     element: <BookPayablesMain />,
//     title: "Booked Payables",
//   },
//   // booked payables order Details
//   {
//     path: `${dashboard}/order`,
//     element: <BookPayablesOrder />,
//     title: "Booked Payables Order",
//   },
//   {
//     path: `${dashboard}/availablerecievables`,
//     element: <AvailableRecievablesMain />,
//     title: "Booked Payables",
//   },
//   // requests Order Details as per status and pages
//   // receivables
//   {
//     path: `${dashboard}/recievableorder`,
//     element: <AvailableOrderDetails />,
//     title: "Available Order Details",
//   },

//   // request Payments Lists
//   {
//     path: `${dashboard}/requestpayments`,
//     element: <AcceptRequestMain />,
//     title: "Booked Payables",
//   },
//   {
//     path: `${dashboard}/requestpayments/order`,
//     element: <AcceptRequestMainPage />,
//     title: "view Payment Request",
//   },
//   {
//     path: `${dashboard}/acceptrequest`,
//     element: <ApprovePaymentRequestList />,
//     title: "Accept Reject Payment Request",
//   },
//   {
//     path: `${dashboard}/acceptrequest/order`,
//     element: <ApproveRejectPaymentRequest />,
//     title: "Accept Reject Payment Request",
//   },
//   // config/paymentrequest
//   {
//     path: `${dashboard}/config/paymentrequest`,
//     element: <PaymentRequestConfig />,
//     title: "Payment Request Config",
//   },
//   // paymentRequests Order Details
//   // {
//   //   path: `${dashboard}/requestorder`,
//   //   element: <RequestOrderDetails />,
//   //   title: "Request Order Details",
//   // },
//   // Expired Payments Details
//   {
//     path: `${dashboard}/expireorder`,
//     element: <ExpiredPaymentRequest />,
//     title: "Expire Order Details",
//   },
//   // InProcess Payments Details
//   {
//     path: `${dashboard}/cancelorder`,
//     element: <CancelOrder />,
//     title: "Canceled Order Details",
//   },
//   // Rejected Payments Details
//   {
//     path: `${dashboard}/rejectorder`,
//     element: <RejectedPaymentRequest />,
//     title: "Rejected Order Details",
//   },
//   {
//     path: `${dashboard}/chat`,
//     element: <Chat />,
//     title: "Chat",
//   },
//   {
//     path: `${dashboard}/rating`,
//     element: <RateYourSeller />,
//     title: "Seller Rating",
//   },
//   { path: `${dashboard}/reports/`, element: <Reports />, title: "Reports" },

//   // New Orders and Payment Request Specifications -

//   {
//     path: `${dashboard}/buyer/orders`,
//     element: <BuyerOrderView />,
//     title: "Buyer Orders",
//   },
//   {
//     path: `${dashboard}/buyer/paymentrequest`,
//     element: <BuyerPaymentRequestView />,
//     title: "Buyer Payment Request",
//   },
//   {
//     path: `${dashboard}/seller/orders`,
//     element: <SellerOrderView />,
//     title: "Seller Orders",
//   },
//   {
//     path: `${dashboard}/seller/paymentrequest`,
//     element: <SellerPaymentRequestView />,
//     title: "Seller Orders",
//   },
//   {
//     path: `${dashboard}/order/chat`,
//     element: <OrderChat />,
//     title: "Payment Request Chat",
//   },
//   {
//     path: `${dashboard}/paymentrequest/chat`,
//     element: <PRChat />,
//     title: "Seller Orders",
//   },
// ];

import React, { Suspense } from "react";
import { routerType } from "../types/router.types";
import LoadingScreen from "src/components/loadingScreen";
import ScreenLoader from "src/components/ScreenLoader";

const OnBoardingPayment = React.lazy(
  () => import("../pages/onBoardingPays/OnBoardingPayment")
);
const ResetPassword = React.lazy(() => import("../pages/login/ResetPassword"));
const CreateUserGstProfile = React.lazy(
  () => import("../pages/GST/CreateUserGstProfile")
);
const CreateBusinessProfile = React.lazy(
  () => import("../pages/GST/CreateBusinessProfile")
);
const AadharVerify = React.lazy(() => import("../pages/Aadhar/AdharVerify"));
const PanVerify = React.lazy(() => import("../pages/Pan/PanVerify"));
const PanUploadFiles = React.lazy(() => import("../pages/Pan/PanUploadFiles"));
const AdminApproved = React.lazy(() => import("../pages/Pan/AdminApproval"));
const Busi1 = React.lazy(() => import("../pages/business/Busi1"));
const Busi2 = React.lazy(() => import("../pages/business/Busi2"));
const Busi3 = React.lazy(() => import("../pages/business/Busi3"));
const ReUpload = React.lazy(() => import("../pages/Reupload/ReuploadDocs"));
const Dashboard = React.lazy(
  () => import("../pages/Payments/Dashboard/Dashboard")
);
const SelectPayment = React.lazy(
  () => import("../pages/Payments/SelectPayment")
);
const CreateNewPaymentBuyer = React.lazy(
  () => import("../pages/Payments/CreatePaymentBuyer/CreateNewPaymentBuyer")
);
const AddMaker = React.lazy(() => import("../pages/Maker/AddMaker"));
const ViewMaker = React.lazy(() => import("../pages/Maker/ViewMaker"));
const PaymentApproved = React.lazy(
  () => import("../pages/Payments/ViewOrders/Completed/CompletOrder")
);
const AcceptPaymentMain = React.lazy(
  () => import("../pages/Payments/AcceptPayment/Accept/AcceptPaymentMain")
);
const ApproveRejectPayment = React.lazy(
  () => import("../pages/Payments/AcceptPayment/ApproveRejectPayment")
);
const MakerRequestMain = React.lazy(
  () => import("../pages/Payments/MakerRequests/MakerRequestMain")
);
const BookPayablesMain = React.lazy(
  () => import("../pages/Payments/BookedPayables/BookPayablesMain")
);
const AvailableRecievablesMain = React.lazy(
  () =>
    import("../pages/Payments/AvailableRecievables/AvailableRecievablesMain")
);
const CreateNewPaymentSeller = React.lazy(
  () => import("../pages/Payments/CreatePaymentSeller/CreateNewPaymentSeller")
);
const AvailableOrderDetails = React.lazy(
  () => import("../pages/Payments/AvailableRecievables/AvailableOrderDetails")
);
const BookPayablesOrder = React.lazy(
  () => import("../pages/Payments/BookedPayables/BookPayablesOrder")
);
const ExpiredPaymentRequest = React.lazy(
  () => import("../pages/Payments/ViewOrders/Expired/ExpiredPaymentRequest")
);
const RejectedPaymentRequest = React.lazy(
  () => import("../pages/Payments/ViewOrders/Rejected/RejectedPaymentRequest")
);
const CancelOrder = React.lazy(
  () => import("../pages/Payments/ViewOrders/Canceled/CancelOrder")
);
const ReuploadRequest = React.lazy(
  () => import("../pages/Reupload/ReuploadRequest")
);
const RateYourSeller = React.lazy(
  () => import("../pages/BusinessRating/RateYourSeller")
);
const GeneratePaymentRequestSeller = React.lazy(
  () =>
    import(
      "../pages/Payments/ViewOrders/InProcess/Seller/GeneratePaymentRequestSeller"
    )
);
const AcceptRequestMain = React.lazy(
  () => import("../pages/Payments/AcceptRequest/Accept/AcceptRequestMain")
);
const AcceptRequestMainPage = React.lazy(
  () => import("../pages/Payments/AcceptRequest/Accept/AcceptRequestMainPage")
);
const ApproveRejectPaymentRequest = React.lazy(
  () => import("../pages/Payments/AcceptRequest/ApproveRejectPaymentRequest")
);
const ApprovePaymentRequestList = React.lazy(
  () => import("../pages/Payments/AcceptRequest/ApprovePaymentRequestList")
);
const PaymentRequestConfig = React.lazy(
  () => import("../pages/Payments/CreatePaymentSeller/PaymentRequestConfig")
);
const BuyerOrder = React.lazy(() => import("../pages/Payments/Orders/Main"));
const SellerOrder = React.lazy(
  () => import("../pages/Payments/RequestOrder/Main")
);
const Chat = React.lazy(() => import("../pages/Chat/Chat/Main"));
const Orders = React.lazy(() => import("../pages/Payments/Dashboard/Orders"));
const Reports = React.lazy(() => import("../pages/Reports/Main"));
const BuyerOrderView = React.lazy(
  () => import("../pages/Payments/Buyer/Orders/Orders")
);
const SellerOrderView = React.lazy(
  () => import("../pages/Payments/Seller/Orders/Orders")
);
const SellerPaymentRequestView = React.lazy(
  () => import("../pages/Payments/Seller/PaymentRequest/PaymentRequest")
);
const BuyerPaymentRequestView = React.lazy(
  () => import("../pages/Payments/Buyer/PaymentRequest/PaymentRequest")
);
const PRChat = React.lazy(() => import("src/pages/Chat/Chat/PRChat"));
const OrderChat = React.lazy(() => import("src/pages/Chat/Chat/OrderChat"));

const auth = "auth";
const dashboard = "dashboard";

const loading = <LoadingScreen />; // You can customize your loading component

export const authrouterData: routerType[] = [
  {
    path: `${auth}/reset`,
    element: (
      <Suspense fallback={loading}>
        <ResetPassword />
      </Suspense>
    ),
    title: "Reset_Password",
  },
  {
    path: `${auth}/onboardingpays`,
    element: (
      <Suspense fallback={loading}>
        <OnBoardingPayment />
      </Suspense>
    ),
    title: "Boarding_Fees",
  },
  {
    path: `${auth}/userkyc`,
    element: (
      <Suspense fallback={loading}>
        <CreateUserGstProfile />
      </Suspense>
    ),
    title: "Create_User_Gst_Profile",
  },
  {
    path: `${auth}/upload-files`,
    element: (
      <Suspense fallback={loading}>
        <CreateBusinessProfile />
      </Suspense>
    ),
    title: "Create_User_Business_Profile",
  },
  {
    path: `${auth}/verifyaadhar`,
    element: (
      <Suspense fallback={loading}>
        <AadharVerify />
      </Suspense>
    ),
    title: "Aadhar_Verify",
  },
  {
    path: `${auth}/verifypan`,
    element: (
      <Suspense fallback={loading}>
        <PanVerify />
      </Suspense>
    ),
    title: "Pan_Verify",
  },
  {
    path: `${auth}/panuploadfiles`,
    element: (
      <Suspense fallback={loading}>
        <PanUploadFiles />
      </Suspense>
    ),
    title: "Pan_Upload_files",
  },
  {
    path: `${auth}/approval`,
    element: (
      <Suspense fallback={loading}>
        <AdminApproved />
      </Suspense>
    ),
    title: "Approval",
  },
  {
    path: `${auth}/postrequest`,
    element: (
      <Suspense fallback={loading}>
        <ReuploadRequest />
      </Suspense>
    ),
    title: "Reupload request",
  },
  {
    path: `${auth}/reupload`,
    element: (
      <Suspense fallback={loading}>
        <ReUpload />
      </Suspense>
    ),
    title: "Reupload",
  },
];

export const dashboardRoutesData: routerType[] = [
  {
    path: `${dashboard}/main`,
    element: (
      <Suspense fallback={loading}>
        <Dashboard />
      </Suspense>
    ),
    title: "Dashboard",
  },
  {
    path: `${dashboard}/orders`,
    element: (
      <Suspense fallback={loading}>
        <Orders />
      </Suspense>
    ),
    title: "My Orders",
  },
  {
    path: `${dashboard}/addbusinessnetwork`,
    element: (
      <Suspense fallback={loading}>
        <Busi1 />
      </Suspense>
    ),
    title: "Approval",
  },
  {
    path: `${dashboard}/businesslist`,
    element: (
      <Suspense fallback={loading}>
        <Busi2 />
      </Suspense>
    ),
    title: "addBusinessNetwork",
  },
  {
    path: `${dashboard}/busi3`,
    element: (
      <Suspense fallback={loading}>
        <Busi3 />
      </Suspense>
    ),
    title: "Busi3",
  },
  {
    path: `${dashboard}/payment`,
    element: (
      <Suspense fallback={loading}>
        <Dashboard />
      </Suspense>
    ),
    title: "Payment Dashboard",
  },
  {
    path: `${dashboard}/selectpayment`,
    element: (
      <Suspense fallback={loading}>
        <SelectPayment />
      </Suspense>
    ),
    title: "select Payment",
  },
  {
    path: `${dashboard}/createpayment`,
    element: (
      <Suspense fallback={loading}>
        <CreateNewPaymentBuyer />
      </Suspense>
    ),
    title: "Create Payment",
  },
  {
    path: `${dashboard}/createpaymentrequest`,
    element: (
      <Suspense fallback={loading}>
        <CreateNewPaymentSeller />
      </Suspense>
    ),
    title: "Create Seller Payment",
  },
  {
    path: `${dashboard}/createmaker`,
    element: (
      <Suspense fallback={loading}>
        <AddMaker />
      </Suspense>
    ),
    title: "Add Maker",
  },
  {
    path: `${dashboard}/users`,
    element: (
      <Suspense fallback={loading}>
        <ViewMaker />
      </Suspense>
    ),
    title: "View Maker",
  },
  {
    path: `${dashboard}/order`,
    element: (
      <Suspense fallback={loading}>
        <BuyerOrder />
      </Suspense>
    ),
    title: "Buyer Orders",
  },
  {
    path: `${dashboard}/request`,
    element: (
      <Suspense fallback={loading}>
        <SellerOrder />
      </Suspense>
    ),
    title: "Buyer Orders",
  },
  {
    path: `${dashboard}/requestedorder`,
    element: (
      <Suspense fallback={loading}>
        <BuyerOrder />
      </Suspense>
    ),
    title: "Buyer Orders",
  },
  {
    path: `${dashboard}/orderrequest`,
    element: (
      <Suspense fallback={loading}>
        <GeneratePaymentRequestSeller />
      </Suspense>
    ),
    title: "Generate Payment Request Seller",
  },
  {
    path: `${dashboard}/completepayment`,
    element: (
      <Suspense fallback={loading}>
        <PaymentApproved />
      </Suspense>
    ),
    title: "Complete Payment",
  },
  {
    path: `${dashboard}/acceptpayments`,
    element: (
      <Suspense fallback={loading}>
        <AcceptPaymentMain />
      </Suspense>
    ),
    title: "Approve Payment",
  },
  {
    path: `${dashboard}/approvepayments`,
    element: (
      <Suspense fallback={loading}>
        <ApproveRejectPayment />
      </Suspense>
    ),
    title: "Accept Payment",
  },
  {
    path: `${dashboard}/makerrequest`,
    element: (
      <Suspense fallback={loading}>
        <MakerRequestMain />
      </Suspense>
    ),
    title: "Maker Request",
  },
  {
    path: `${dashboard}/bookpayables`,
    element: (
      <Suspense fallback={loading}>
        <BookPayablesMain />
      </Suspense>
    ),
    title: "Booked Payables",
  },
  {
    path: `${dashboard}/order`,
    element: (
      <Suspense fallback={loading}>
        <BookPayablesOrder />
      </Suspense>
    ),
    title: "Booked Payables Order",
  },
  {
    path: `${dashboard}/availablerecievables`,
    element: (
      <Suspense fallback={loading}>
        <AvailableRecievablesMain />
      </Suspense>
    ),
    title: "Booked Payables",
  },
  {
    path: `${dashboard}/recievableorder`,
    element: (
      <Suspense fallback={loading}>
        <AvailableOrderDetails />
      </Suspense>
    ),
    title: "Available Order Details",
  },
  {
    path: `${dashboard}/requestpayments`,
    element: (
      <Suspense fallback={loading}>
        <AcceptRequestMain />
      </Suspense>
    ),
    title: "Booked Payables",
  },
  {
    path: `${dashboard}/requestpayments/order`,
    element: (
      <Suspense fallback={loading}>
        <AcceptRequestMainPage />
      </Suspense>
    ),
    title: "view Payment Request",
  },
  {
    path: `${dashboard}/acceptrequest`,
    element: (
      <Suspense fallback={loading}>
        <ApprovePaymentRequestList />
      </Suspense>
    ),
    title: "Accept Reject Payment Request",
  },
  {
    path: `${dashboard}/acceptrequest/order`,
    element: (
      <Suspense fallback={loading}>
        <ApproveRejectPaymentRequest />
      </Suspense>
    ),
    title: "Accept Reject Payment Request",
  },
  {
    path: `${dashboard}/config/paymentrequest`,
    element: (
      <Suspense fallback={loading}>
        <PaymentRequestConfig />
      </Suspense>
    ),
    title: "Payment Request Configuration",
  },
  {
    path: `${dashboard}/rateyourbusiness`,
    element: (
      <Suspense fallback={loading}>
        <RateYourSeller />
      </Suspense>
    ),
    title: "Rate your Business",
  },
  {
    path: `${dashboard}/chat`,
    element: (
      <Suspense fallback={loading}>
        <Chat />
      </Suspense>
    ),
    title: "Chat",
  },
  {
    path: `${dashboard}/chat/order`,
    element: (
      <Suspense fallback={loading}>
        <OrderChat />
      </Suspense>
    ),
    title: "Order Chat",
  },
  {
    path: `${dashboard}/chat/paymentrequest`,
    element: (
      <Suspense fallback={loading}>
        <PRChat />
      </Suspense>
    ),
    title: "Payment Request Chat",
  },
  {
    path: `${dashboard}/reports`,
    element: (
      <Suspense fallback={loading}>
        <Reports />
      </Suspense>
    ),
    title: "Reports",
  },
  {
    path: `${dashboard}//buyer/orders`,
    element: (
      <Suspense fallback={loading}>
        <BuyerOrderView />
      </Suspense>
    ),
    title: "Buyer Orders",
  },
  {
    path: `${dashboard}/buyer/paymentrequest`,
    element: (
      <Suspense fallback={loading}>
        <BuyerPaymentRequestView />
      </Suspense>
    ),
    title: "Buyer Payment Request",
  },
  {
    path: `${dashboard}/seller/orders`,
    element: (
      <Suspense fallback={loading}>
        <SellerOrderView />
      </Suspense>
    ),
    title: "Seller Orders",
  },
  {
    path: `${dashboard}/seller/paymentrequest`,
    element: (
      <Suspense fallback={loading}>
        <SellerPaymentRequestView />
      </Suspense>
    ),
    title: "Seller Payment Request",
  },
  {
    path: `${dashboard}/order/chat`,
    element: (
      <Suspense fallback={loading}>
        <OrderChat />
      </Suspense>
    ),
    title: "Order Chat",
  },
  {
    path: `${dashboard}/paymentrequest/chat`,
    element: (
      <Suspense fallback={loading}>
        <PRChat />
      </Suspense>
    ),
    title: "Seller Chat",
  },
];

// {
//     path: `${dashboard}/buyer/orders`,
//     element: <BuyerOrderView />,
//     title: "Buyer Orders",
//   },
//   {
//     path: `${dashboard}/buyer/paymentrequest`,
//     element: <BuyerPaymentRequestView />,
//     title: "Buyer Payment Request",
//   },
//   {
//     path: `${dashboard}/seller/orders`,
//     element: <SellerOrderView />,
//     title: "Seller Orders",
//   },
//   {
//     path: `${dashboard}/seller/paymentrequest`,
//     element: <SellerPaymentRequestView />,
//     title: "Seller Orders",
//   },
//   {
//     path: `${dashboard}/order/chat`,
//     element: <OrderChat />,
//     title: "Payment Request Chat",
//   },
//   {
//     path: `${dashboard}/paymentrequest/chat`,
//     element: <PRChat />,
//     title: "Seller Orders",
//   },
// ];
