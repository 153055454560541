import { enqueueSnackbar } from "notistack";

export const ErrorMsg = (value: any) => {
  return enqueueSnackbar(`${value}`, {
    variant: "error",
    style: {
      color: "white",
      backgroundColor: "vred",
      marginRight: "100px",
      fontFamily: "Public Sans",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "22px",
      maxWidth: "400px",
      margin: "50px 0 0 0",
    },
  });
};
