import { messaging } from "./firebase";
import { onMessage, getToken } from "firebase/messaging";

const initializeNotification = (
  handleNewNotification: (notification: any) => void
) => {
  Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");

        getToken(messaging, {
          vapidKey:
            "BAhWen14Bgdy6SgATQTRJm2SH8WuQ98thW383UfodC15bwg-zbA56RWZptgrVmiBbLNwcVXATcXozm-_HyDsIIk",
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log("Device Token:", currentToken);
              return true;
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
              false;
            }
          })
          .catch((err) => {
            console.error("An error occurred while retrieving token:", err);
          });
      } else if (permission === "denied") {
        console.log("Notification permission denied.");
      }
    })
    .catch((err) => {
      console.error("Error requesting notification permission:", err);
    });

  onMessage(messaging, (payload) => {
    console.log("Message received: ", payload);
    if (payload && payload.notification) {
      const { title, body }: any = payload.notification;
      new Notification(title, { body });
      handleNewNotification({ title, body });
    }
  });
};

export default initializeNotification;
