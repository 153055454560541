import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBQXYoLOgNxGidlnFbyX0klCUK7AzNqIOI",
  authDomain: "assuredpay-d44cd.firebaseapp.com",
  projectId: "assuredpay-d44cd",
  storageBucket: "assuredpay-d44cd.firebasestorage.app",
  messagingSenderId: "625559916502",
  appId: "1:625559916502:web:c2bd5afe2db590fe97de77",
  measurementId: "G-76387XZJM3",
};

export const app = initializeApp(firebaseConfig);
export const messaging: any = getMessaging(app);
