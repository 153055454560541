import ApiService from "../api/api";
import { ErrorMsg } from "../components/Messages/ErrorMsg";
import { tokenSessionTimeout } from "../globalAction/action";

export const checkloginAuthentication = async (token: string) => {
  if (!token) {
    return;
  }
  const validateToken: any = await tokenSessionTimeout(token);
  console.log(validateToken);
  if (!validateToken) {
    return;
  }

  await ApiService.setToken(token);

  // API call to get profile status
  try {
    const response: any = await ApiService.get(`/auth/profileStatus`);
    if (response.status === 200) {
      const profileStatus = response.data.result.profileStatus;
      localStorage.setItem("profileStatus", profileStatus);
      return { success: true, profileStatus };
    } else {
      return { success: false, profileStatus: null };
    }
  } catch (error) {
    ErrorMsg("Invalid Token, Please login again.");

    console.error("Error during profileStatus API call", error);
    return { success: false, profileStatus: null };
  }
};
