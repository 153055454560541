import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const EnableNotificationPermission = ({
  open,
  onClose,
  onPermissionChange,
}: {
  open: boolean;
  onClose: () => void;
  onPermissionChange: (value: boolean) => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 4,
          overflow: "hidden",
          transition: "transform 0.3s ease-in-out",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            Notification Permission Required
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box textAlign="center" sx={{ my: 1 }}>
          <Box display={"flex"} justifyContent={"center"} sx={{ mb: 2 }}>
            {" "}
            <NotificationsActiveIcon sx={{ fontSize: 60, color: "gray" }} />
          </Box>
        </Box>

        <Card
          variant="outlined"
          sx={{
            mb: 3,
            borderRadius: 2,
            overflow: "hidden",
            boxShadow: 1,
            p: 1,
            transition: "box-shadow 0.3s",
            "&:hover": {
              boxShadow: 2,
            },
          }}
        >
          <CardContent>
            <Typography color={"grey"} fontFamily={"Public Sans"} gutterBottom>
              {" "}
              Allow Notifications Access, Follow the steps below to enable
              notifications:
            </Typography>
            <ol
              style={{
                paddingInlineStart: "20px",
                margin: 1,
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <li>
                {" "}
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={14}
                  fontWeight={500}
                >
                  Click the notification bell on your device.
                </Typography>
              </li>
              <li>
                {" "}
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={14}
                  fontWeight={500}
                >
                  Choose "Allow" when prompted to enable notifications.
                </Typography>
              </li>
              <li>
                {" "}
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={14}
                  fontWeight={500}
                >
                  Enjoy seamless updates from our app.
                </Typography>
              </li>
            </ol>
          </CardContent>
        </Card>

        <Box textAlign="start" sx={{ mt: 1 }} mx={1} mb={2}>
          <Typography
            variant="body1"
            sx={{ color: "gray", fontFamily: "Public Sans", fontSize: 14 }}
          >
            Stay updated with important notifications by enabling permissions.
          </Typography>
          <Stack direction={"row"} spacing={1} m={2} mt={4}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                mb: 2,
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "8px",
                transition: "all 0.3s",
                color: "white",
                backgroundColor: "#334A67",
                "&:hover": {
                  backgroundColor: "#334A67",
                  color: "white",
                },
              }}
              onClick={() => {
                onPermissionChange(true); // Send true to the parent
                onClose(); // Close the modal
              }}
            >
              Access Denied
            </Button>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "8px",
                transition: "all 0.3s",
                color: "white",
                backgroundColor: "#334A67",
                "&:hover": {
                  backgroundColor: "#334A67",
                  color: "white",
                },
              }}
              onClick={onClose}
            >
              Enable
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EnableNotificationPermission;
