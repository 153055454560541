import { ErrorMsg } from "../components/Messages/ErrorMsg";

export const loginAction = (token: string) => {
  if (!token) {
    return false;
  }
  localStorage.setItem("token", token);
  tokenSessionTimeout(token);
  return true;
};
export const logoutAction = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("profileStatus");
  window.location.href = "/login";
  return true;
};

export const decodeToken = (token: string): any => {
  try {
    const payload = token.split(".")[1];
    return JSON.parse(atob(payload));
  } catch (error) {
    console.error("Invalid token format", error);
    return null;
  }
};

export const tokenSessionTimeout = async (token: string) => {
  const decodedToken = decodeToken(token);
  if (!decodedToken || !decodedToken.exp) {
    console.error("Token does not contain expiration time.");
    return;
  }

  const expirationTime = decodedToken.exp * 1000;
  const currentTime = Date.now();
  console.log("currentTime", currentTime);
  console.log("expirationTime", expirationTime);

  if (currentTime >= expirationTime) {
    console.error("Token has expired.");
    return false;
  }

  const timeout = expirationTime - currentTime;
  console.log("Timeout", timeout);
  setTimeout(() => {
    console.error("Token has expired, Please try again !");
    ErrorMsg("Token has expired, Please try again !");
    localStorage.removeItem("token");
    window.location.href = "/login";
  }, timeout);
  return true;
};
