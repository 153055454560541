import { Box, Typography } from "@mui/material";

const ScreenLoader: React.FC = () => {
  const fullText = "Please Wait, Screen is loading . . .";

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      my={4}
      width="100%"
    >
      <Box
        sx={{
          position: "relative",
          width: "25px",
          height: "25px",
          mb: 2,
        }}
      >
        {Array.from({ length: 12 }).map((_, index) => (
          <Box
            key={index}
            sx={{
              position: "absolute",
              width: "4px",
              height: "10px",
              backgroundColor: "#303981",
              borderRadius: "2px",
              transformOrigin: "12.5px 12.5px",
              transform: `rotate(${index * 30}deg) translate(0, -10px)`,
              animation: `fade 1.2s infinite`,
              animationDelay: `${index * 0.1}s`,
            }}
          />
        ))}
        <style>
          {`
            @keyframes fade {
              0%, 100% { opacity: 0; }
              50% { opacity: 1; }
            }
          `}
        </style>
      </Box>

      <Typography fontSize={14} color="#757575" mt={1}>
        {fullText}
      </Typography>
    </Box>
  );
};

export default ScreenLoader;
