import axios from "axios";
import { ErrorMsg } from "src/components/Messages/ErrorMsg";

const baseURL = process.env.REACT_APP_BASE_URL;
if (!baseURL) {
  console.error("REACT_APP_BASE_URL is not defined in the .env file");
  ErrorMsg("REACT_APP_BASE_URL is not defined in the .env file");
}
const apiService = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const ApiService = {
  getUserCoordinates: () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords, timestamp } = position;
          const LocationInfoData = {
            timestamp: timestamp,
            latitude: coords.latitude,
            longitude: coords.longitude,
            accuracy: coords.accuracy,
          };
          resolve(LocationInfoData);
          return LocationInfoData;
        },
        (error) => {
          reject(error);
          return error;
        }
      );
    });
  },
  setToken: (token: string) => {
    apiService.defaults.headers.common["token"] = `${token}`;
  },

  addCoordinatesToHeaders: async (isLocationNeeded: boolean) => {
    if (isLocationNeeded) {
      try {
        const userCoordinates: any = await ApiService.getUserCoordinates();
        apiService.defaults.headers.common["lat"] = userCoordinates.latitude;
        apiService.defaults.headers.common["lng"] = userCoordinates.longitude;
      } catch (error) {
        console.error("Could not get user coordinates:", error);
      }
    }
  },

  get: async (endpoint: string, params = {}, isLocationNeeded = false) => {
    await ApiService.addCoordinatesToHeaders(isLocationNeeded);
    try {
      const response = await apiService.get(endpoint, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  post: async (
    endpoint: any,
    data: any,
    params = {},
    isLocationNeeded = false
  ) => {
    await ApiService.addCoordinatesToHeaders(isLocationNeeded);
    try {
      const response = await apiService.post(endpoint, data, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  put: async (
    endpoint: any,
    data: any,
    params = {},
    isLocationNeeded = false
  ) => {
    await ApiService.addCoordinatesToHeaders(isLocationNeeded);
    try {
      const response = await apiService.put(endpoint, data, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  patch: async (
    endpoint: any,
    data: any,
    params = {},
    isLocationNeeded = false
  ) => {
    await ApiService.addCoordinatesToHeaders(isLocationNeeded);
    try {
      const response = await apiService.patch(endpoint, data, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  delete: async (endpoint: any, params = {}, isLocationNeeded = false) => {
    await ApiService.addCoordinatesToHeaders(isLocationNeeded);
    try {
      const response = await apiService.delete(endpoint, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },
};

export default ApiService;
