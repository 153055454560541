// These are the protected Routes pages for handling or providing safe accessibility for applications
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ProtectedRoutes = () => {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default ProtectedRoutes;
